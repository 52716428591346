import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Logo from '../images/new-horizontal-color.png'
import './header.scss'
import { LineButton } from "./atoms/buttons/LineButton"
import MobileMenu from "./mobileMenu.tsx"
import trackEvent from "../analyticsHelpers"
import ArrowDown from '../images/arrow-down.png'
import RegularButton from '../components/atoms/buttons/RegularButton'
import { getAndroidOperatingSystem, goToApp } from "../utils"
import ImgBanner from '../images/image-banner.png'
import FontIcon from "./atoms/fontIcon/FontIcon"

declare global { interface Window { analytics: any; } }

const isBrowser = typeof window !== "undefined"

const Header = () => {
  const [navbar, setNavbar] = useState(false)
  const [isBannerClicked, setIsBannerClicked] = useState(false)
  const [userAgentAndroid, setUserAgentAndroid] = useState(false)
  let isAndroid
  const changeBackground = () => {
    if (window.scrollY >= 100) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }
  const goToStore = () => {
    localStorage.setItem('android', 'true')
    goToApp('https://play.google.com/store/apps/details?id=br.com.theia', 'sitenovo-home-clicou-banner-android')
  }

  const closeBanner = () => {
    localStorage.setItem('android', 'true')
    setIsBannerClicked(true)
  }
  useEffect(() => {
    changeBackground()
    if (isBrowser)  window.addEventListener("scroll", changeBackground)
  }, [])

  useEffect(() => {
    setUserAgentAndroid(getAndroidOperatingSystem())
  }, [])

  useEffect(() => {
    if (typeof localStorage !== 'undefined') {
      isAndroid = localStorage.getItem('android')
      if (isAndroid === 'true') setIsBannerClicked(true)
    }
  }, [])
  return (
    <>
    {userAgentAndroid && !isBannerClicked &&
      (
        <div className="bg-bgButton flex absolute p-4 items-center z-1000 top-0">
          <img src={ImgBanner} className="flex-none" onClick={goToStore} />
          <p className="text-textSmaller text-textPrimary w-4/5 ml-2" onClick={goToStore}>Baixe no seu celular o app da Theia e acesse de qualquer lugar.</p>
          <button type="button" onClick={closeBanner} className="p-4">
            <FontIcon iconType="icon-Property-2Close" fontSize="24" />
          </button>
        </div>  
      )
    }
    <header id="menu">
      <div className={`items-center pt-5 pb-5 flex header full-header justify-between ${navbar ? "menuFixo" : ""}`}>
        <div className="logo-theia">
          <Link to="https://theia.com.br/" onClick={() => trackEvent("sitenovo-menu-clicou-logo")}>
            <img src={Logo} alt="Theia | A clínica moderna centrada na gestante " />
          </Link>
        </div>
        <div className="mobile-menu">
          <MobileMenu />
        </div>
        <div className="flex-1 justify-end items-center menu-desk">
          <RegularButton
            onClick={() => goToApp('https://app.theia.com.br/login', 'sitenovo-menu-clicou-entrarnatheia')}
            label="Login"
            variant="text"
            extraClass="flex-none mr-6"
            width="70px"
          />
          <RegularButton
            onClick={() => goToApp('https://app.theia.com.br/boas-vindas', 'sitenovo-menu-clicou-comecarnatheia')}
            label="Começar"
            extraClass="flex-none"
            width="168px"
          />
        </div>
      </div>
    </header>
    </>
  )
}

export default Header
