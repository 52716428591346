import React from "react"
import Header from "./header"
import { PreFooterForm } from './preFooterForm'
import Footer from './footer'
import "./layout.scss"
import CookiesBanner from './cookiesBanner'

export interface Props {
  children: React.ReactNode;
}

const Layout = ({ children }) => {

  return (
    <div className="main-container mx-auto">
      <Header />
      <div>
        <main>{children}</main>
      </div>
    </div>
  )
}

export default Layout
