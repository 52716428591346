import React from 'react'

export interface Props {
  color?: string;
  iconType?: string;
  fontSize?: string;
  animation?: string;
  extraClass?: string;
}

const FontIcon = ({
  color,
  iconType,
  fontSize,
  animation,
  extraClass
}: Props) => (
  <i
    className={`${iconType} ${extraClass}`}
    style={{
      color,
      fontSize,
      animation
    }}
  />
)
export default FontIcon
