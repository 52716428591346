import React, { useState, useRef } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { LineButton } from "./atoms/buttons/LineButton"
import trackEvent from "../analyticsHelpers"
import RegularButton from '../components/atoms/buttons/RegularButton'

const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;;
  background: #FFFCFC;
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
  height: 100vh;
  text-align: left;
  padding: 20px;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  overflow: scroll;
  @media (max-width: 576px) {
      width: 100%;
    }

  a.menu-item {
    font-size: 24px;
    line-height: 28px;
    color: #3B149C;
    font-weight: bold;
    margin: 0 0 24px;
    text-decoration: underline;
    :first-child {
    margin: 64px 0 24px;
    }
  }
  button {
    margin-bottom: 36px;
    padding: 25px 0;
    line-height: 0;
    width: 70%;
  }
`

const Menu = ({ open }) => {

  const goToApp = (path: string) => {
    if (path === 'login') {
      location.href = 'https://app.theia.com.br/login'
      trackEvent("sitenovo-menu-clicou-entrarnatheia")
    } else {
      location.href = 'https://app.theia.com.br/boas-vindas'
      trackEvent("sitenovo-menu-clicou-comecarnatheia")
    }
  }
  
  
  return (
    <StyledMenu open={open}>
      <div className="flex mt-12">
       <RegularButton
          onClick={() => goToApp('login')}
          label="Entrar"
          variant="secondary"
        />
        <RegularButton
          onClick={() => goToApp('signup')}
          label="Começar"
          extraClass="ml-4"
        />
        </div>
    </StyledMenu>
  )
}

const StyledBurger = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: #3B149C;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    :nth-child(2) {
      opacity: ${({ open }) => open ? '0' : '1'};
      transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
    }

    :nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`

const Burger = ({ open, setOpen }) => {
  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)}>
      <div />
      <div />
      <div />
    </StyledBurger>
  )
}

const MobileMenu = () => {
  const [open, setOpen] = useState(false);
  const node = useRef();
  return (
    <div ref={node}>
      <Burger open={open} setOpen={setOpen} />
      <Menu open={open} setOpen={setOpen} />
    </div>
  )
  
}

export default MobileMenu