import React from 'react'

export interface Props {
  onClick?: () => void;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  id?: string;
  borderRadius?: string;
  type?: 'button' | 'submit' | 'reset';
  IconStart?: React.ReactNode;
  IconEnd?: React.ReactNode;
  width?: string;
  maxWidth?: string;
  height?: string;
}

export function Button({
  onClick,
  children,
  className,
  disabled,
  id,
  borderRadius,
  type = 'button',
  IconStart,
  IconEnd,
  width,
  height,
  maxWidth,
  ...props
}: Props) {
  return (
    <button
      id={id}
      type={type}
      onClick={onClick}
      className={`${className} ${disabled ? 'cursor-default' : ''}`}
      onKeyPress={onClick}
      style={{
        height,
        borderRadius,
        width,
        maxWidth
      }}
      disabled={disabled}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <>
        <span>{IconStart}</span>
        {children}
        <span>{IconEnd}</span>
      </>
    </button>
  )
}
