/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { getKeyValue } from '../../../utils'
import FontIcon from '../fontIcon/FontIcon'
import { Button } from './BaseButton'
import './RegularButton.scss'

export interface Props {
  variant?: any;
  isSubmitting?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  width?: string;
  maxWidth?: string;
  height?: string;
  iconType?: string;
  btnSize?: any;
  label?: string;
  extraClass?: string;
  iconSize?: string;
  hasIcon?: boolean;
  type?: 'button' | 'submit' | 'reset';
  id?: string;
}

const mapVariant = {
  primary: 'primary',
  secondary: 'secondary',
  subtle: 'subtle',
  text: 'text',
  secondaryText: 'secondary-text'
}

const mapBtnSize = {
  sm: 'small-btn-material',
  lg: 'large-btn-material'
};

export default function RegularButton({
  variant = 'primary',
  isSubmitting,
  onClick,
  disabled,
  iconType,
  btnSize = 'lg',
  width,
  label,
  extraClass,
  maxWidth,
  iconSize,
  type = 'button',
  hasIcon = false,
  id,
  ...props
}: Props) {
  return (
    <Button
      className={`${extraClass} text-center btn-material ${getKeyValue(mapBtnSize, btnSize)} ${getKeyValue(mapVariant, variant)} ${disabled ? 'disabled' : ''}`}
      onClick={onClick}
      width={width}
      disabled={isSubmitting || disabled}
      maxWidth={maxWidth}
      type={type}
      id={id}
      {...props}
      IconStart={!isSubmitting && hasIcon && <FontIcon iconType={iconType} fontSize={iconSize} extraClass="mr-2" />}
    >
      <p className={`label-btn-material ${getKeyValue(mapVariant, variant)}`}>{label}</p>
    </Button>
  )
}